/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import firebase from "gatsby-plugin-firebase";
import tw, { css } from "twin.macro";
import { ProfileIcon } from "~components";
import { useAuth, useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as Token } from "~assets/icons/token-new.svg";

const AccountCard = ({ onPurchase = () => {}, onTransactions = () => {} }) => {
  // ==========================================================================
  // context / ref / state

  const { account, logout, loggedIn, updateCurrentAccount } = useAuth();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const [uploading, setUploading] = useState(false);

  const imageUploadRef = useRef();

  // ==========================================================================
  // methods

  const doLogout = async () => {
    await logout();

    navigate(`/app/login`);
  };

  const clearProfileImage = async () => {
    setUploading(true);

    await updateCurrentAccount({
      avatar: null
    });

    setUploading(false);
  };

  const handleFileUpload = (event) => {
    event.preventDefault();

    const file = event.target?.files[0];

    if (!file || uploading) {
      return;
    }

    setUploading(true);

    const storageRef = firebase.storage().ref();
    const imageURL = `images/${account.id}/${file.name}`;
    const ref = storageRef.child(imageURL);

    ref.put(file).then((snapshot) => {
      setUploading(false);

      updateCurrentAccount({
        avatar: imageURL
      });
    });
  };

  // ==========================================================================
  // lifecycle

  //

  // ==========================================================================
  // render

  if (!account) {
    return <></>;
  }

  return (
    <article
      css={[
        css`
          animation: 0.5s ease appear-down forwards;

          transform: translate3d(0, -1rem, 0);
          opacity: 0;
        `,
        tw`w-full relative p-5 rounded-md bg-grey-mid`
      ]}
    >
      <div
        css={[
          css`
            border-color: #1a1a19;
          `,
          tw`w-full relative mb-8 pb-8 flex items-center border-b`
        ]}
      >
        <div
          css={[tw`w-1/3 md:w-1/2 relative flex items-center justify-center`]}
        >
          <div
            css={[
              css`
                width: 3rem;
                height: 3rem;
                position: relative;

                ${MEDIA_QUERIES.desktop} {
                  width: 4.25vw;
                  height: 4.25vw;
                }
              `
            ]}
          >
            <ProfileIcon badges color={lowImpactMode ? `black` : `#305247`} />
          </div>
        </div>

        <div css={[tw`w-2/3 md:w-1/2 relative pl-2`]}>
          <div
            css={[
              css`
                ${colourTransitionCSS};

                min-height: 2.5rem;
                border: 1px solid
                  ${lowImpactMode ? colours.foreground : colours.background};
                color: ${lowImpactMode
                  ? colours.foreground
                  : colours.background};
                font-size: 16px !important;

                opacity: ${uploading ? `0.5` : `1`};
                pointer-events: ${uploading ? `none` : `auto`};

                ${MEDIA_QUERIES.desktop} {
                  font-size: 20px !important;
                }
              `,
              tw`w-full relative flex items-center justify-center rounded-md border font-body text-button md:text-button-md`
            ]}
          >
            <input
              id="avatar"
              ref={imageUploadRef}
              type="file"
              name="avatar"
              onChange={handleFileUpload}
              css={[
                tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 opacity-0 cursor-pointer`
              ]}
              accept="image/png,image/jpeg,image/jpg"
            />

            <span css={[tw`block mt-1`]}>Upload new image</span>
          </div>

          <button
            css={[
              css`
                ${colourTransitionCSS};

                min-height: 2.5rem;
                border: 1px solid
                  ${lowImpactMode ? colours.foreground : colours.background};
                color: ${lowImpactMode
                  ? colours.foreground
                  : colours.background};
                font-size: 16px !important;

                opacity: ${uploading ? `0.5` : `1`};
                pointer-events: ${uploading ? `none` : `auto`};

                ${MEDIA_QUERIES.desktop} {
                  font-size: 20px !important;
                }
              `,
              tw`w-full relative rounded-md mt-1 border font-body text-button md:text-button-md`
            ]}
            onClick={() => clearProfileImage()}
            type="button"
          >
            <span css={[tw`block mt-1`]}>Use default</span>
          </button>
        </div>
      </div>

      {/* <div css={[tw`w-full relative flex items-stretch pt-5`]}> */}
      <div css={[tw`w-full relative flex items-stretch`]}>
        <div css={[tw`w-1/3 md:w-1/2 relative pr-2`]}>
          {typeof account?.tokens !== `undefined` && (
            <div
              css={[
                css`
                  ${colourTransitionCSS};

                  height: 88px;
                  background: ${colours.foreground};
                  color: ${colours.background};
                `,
                tw`w-full relative block rounded-md overflow-hidden flex items-center justify-center pl-3 md:pl-0 text-center`
              ]}
            >
              <div
                css={[
                  tw`w-1/3 relative flex items-center justify-end pt-4 pb-4`
                ]}
              >
                <Token
                  css={[tw`w-8 h-8 relative block`]}
                  fill={colours.background}
                />
              </div>

              <span
                css={[
                  tw`w-2/3 relative block font-body text-b1 md:text-button-md`
                ]}
              >
                {account.tokens}
              </span>
            </div>
          )}

          <button
            css={[
              css`
                ${colourTransitionCSS};

                min-height: 2.5rem;
                font-size: 20px !important;
                background: transparent;
                color: ${lowImpactMode
                  ? colours.foreground
                  : colours.background};
              `,
              tw`w-full relative rounded-md mt-2 bg-grey-dark font-body text-button md:text-button-md`
            ]}
            onClick={() => {
              if (!loggedIn) {
                return;
              }

              doLogout();
            }}
            type="button"
          >
            <span css={[tw`block py-1`]}>Sign out</span>
          </button>
        </div>

        <div css={[tw`w-2/3 md:w-1/2 relative pl-2`]}>
          <button
            css={[
              css`
                ${colourTransitionCSS};

                min-height: 2.5rem;
                font-size: 16px !important;
                background: transparent;
                border: 1px solid
                  ${lowImpactMode ? colours.foreground : colours.background};
                color: ${lowImpactMode
                  ? colours.foreground
                  : colours.background};

                ${MEDIA_QUERIES.desktop} {
                  font-size: 20px !important;
                }
              `,
              tw`w-full relative rounded-md font-body text-button md:text-button-md`
            ]}
            onClick={onPurchase}
            type="button"
          >
            <span css={[tw`block py-1`]}>Purchase tokens</span>
          </button>

          <button
            css={[
              css`
                ${colourTransitionCSS};

                min-height: 2.5rem;
                font-size: 16px !important;
                background: transparent;
                border: 1px solid
                  ${lowImpactMode ? colours.foreground : colours.background};
                color: ${lowImpactMode
                  ? colours.foreground
                  : colours.background};

                ${MEDIA_QUERIES.desktop} {
                  font-size: 20px !important;
                }
              `,
              tw`w-full relative rounded-md mt-2 font-body text-button md:text-button-md`
            ]}
            onClick={onTransactions}
            type="button"
          >
            <span css={[tw`block py-1`]}>Transaction history</span>
          </button>

          <a
            href="https://drive.google.com/drive/folders/1F11CQrAIShQ-Q0scAjHgAhHdzuANR1xL?usp=sharing"
            rel="noopener noreferrer"
            target="_blank"
          >
            <button
              type="button"
              css={[
                css`
                  ${colourTransitionCSS};

                  min-height: 2.5rem;
                  font-size: 16px !important;
                  background: transparent;
                  border: 1px solid
                    ${lowImpactMode ? colours.foreground : colours.background};
                  color: ${lowImpactMode
                    ? colours.foreground
                    : colours.background};

                  ${MEDIA_QUERIES.desktop} {
                    font-size: 20px !important;
                  }
                `,
                tw`w-full relative block rounded-md mt-2 font-body text-button text-center md:text-button-md`
              ]}
            >
              <span css={[tw`block py-1`]}>Onboarding Documents</span>
            </button>
          </a>
        </div>
      </div>
    </article>
  );
};

export default AccountCard;
